$color1: #ecede7;
$color2: #b8d2e1;
$color3: #758c9b;
$color4: #304856;
$color5: #28739e;
$accent: #d4ac2a;

$main-font: 'Raleway', sans-serif;

* {
    font-family: $main-font;
}

section {
	background-color: $color1;
	color: $color4;
}

section wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    height: 800px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
	border-bottom: 1px solid black;
}

a.NewsLink {
  color: #117bb8;
  text-decoration: none;

  &:hover {
    color: #758c9b;
  }
}